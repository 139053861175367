<template>
  <v-card class="mx-auto form-card-padding text-center">
    <template v-if="setup.length == 0">
      <div>
        {{ $t('components.charts.loading') }}
      </div>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
        center
      ></v-progress-circular>
    </template>

    <template v-if="setup.length != 0">
      <apexchart
        :options="options"
        :series="series"
      ></apexchart>
      <v-select
        v-model="selectedGroup"
        :items="sectors"
        :menu-props="{ maxHeight: '400' }"
        :label="$t('components.charts.sectorsAndApplications.selector.label')"
        :hint="$t('components.charts.sectorsAndApplications.selector.hint')"
        persistent-hint
        @change="updateChart()"
      ></v-select>
    </template>
  </v-card>
</template>

<script>
import apexchart from 'vue-apexcharts'

export default {
  components: { apexchart },
  name: 'ProjectSectorsAndApplications',

  props: {
    setup: Array
  },
  watch: {
    setup: {
      deep: true,
      handler () {
        if (this.setup.length == 0) return

        //every time new setup is recieved delete the old values
        this.selectedGroup = null
        this.sectors = []
        this.groups = {}
        this.series = []

        this.setup.forEach((n) => {
          //if the group dosent exist create it
          let group = n.sectorName
          if (!(group in this.groups)) {
            this.groups[group] = {
              seriesData: [],
              labels: []
            }
            this.sectors.push(group)
          }
          this.groups[group].seriesData.push(n.count)
          this.groups[group].labels.push(n.sectorApplicationName)
        })

        //we display first key
        let [firstKey] = Object.keys(this.groups)
        this.series = this.groups[firstKey].seriesData
        this.options.labels = this.groups[firstKey].labels
        this.selectedGroup = firstKey
      }
    }
  },
  data () {
    return {
      selectedGroup: null,
      sectors: [],
      groups: {},
      series: [],
      options: {
        chart: {
          //width: 380,
          type: 'donut'
        },
        labels: [],
        responsive: [
          {
            //breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true
                }
              }
            }
          }
        },
      }
    }
  },
  methods: {
    updateChart(){
      this.options = { ...this.options, ... {labels:this.groups[this.selectedGroup].labels}}
      this.series = this.groups[this.selectedGroup].seriesData
    }
  },
}
</script>

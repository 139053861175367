<template>
  <v-card class="mx-auto form-card-padding text-center">
    <template v-if="setup.length == 0">
      <div>
        {{ $t('components.charts.loading') }}
      </div>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
        center
      ></v-progress-circular>
    </template>

    <template v-if="setup.length != 0">
      <apexchart
        :options="options"
        :series="series"
      ></apexchart>
    </template>
  </v-card>
</template>

<script>
import apexchart from 'vue-apexcharts'

export default {
  components: { apexchart },
  name: 'ProjectSectors',

  props: {
    setup: Array
  },
  watch: {
    setup: {
      deep: true,
      handler () {
        if (this.setup.length == 0) return

        let seriesData = []
        let typesData = []

        this.setup.forEach(function (n) {
          seriesData.push(n.count)
          typesData.push(n.internal_name)
        })

        this.series = seriesData
        this.options.labels = typesData
      }
    }
  },
  data () {
    return {
      selectedGroup: null,
      sectors: [],
      groups: {},
      series: [],
      options: {
        chart: {
          //width: 380,
          type: 'donut'
        },
        labels: [],
        responsive: [
          {
            //breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true
                }
              }
            }
          }
        },
      }
    }
  },
}
</script>

<template>
  <v-card class="mx-auto form-card-padding text-center">
    <template v-if="setup.length == 0">
      <div>
        {{ $t('components.charts.loading') }}
      </div>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
        center
      ></v-progress-circular>
    </template>

    <apexchart v-if="setup.length != 0" :options="options" :series="options.series"></apexchart>
  </v-card>
</template>

<script>
import apexchart from 'vue-apexcharts'

export default {
  components: { apexchart },
  name: 'SalesByCountry',

  props: {
    setup: Array
  },
  watch: {
    setup: {
      deep: true,
      handler () {
        let seriesData = []

        this.setup.forEach(function (n) {
          seriesData.push(
            {
              name: n.country,
              data: [
                {x: n.country, y: n.count}
              ]
            }  
          )
        })

        this.options.series = seriesData

        this.options = { ...this.options }
      }
    }
  },
  data () {
    return {
      options: {
        series: [
          /*{
            name: this.$t('dashboard.salesByCountry.title'),
            data: []
          }*/
        ],
        chart: {
          type: 'treemap',
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
            treemap: {
                distributed: true,
                enableShades: false
            }
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, /*opt*/) {
            //console.log(val)
            //console.log(opt)
            return val
            //return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
          text: this.$t('dashboard.salesByCountry.title'),
          align: 'center',
          floating: true
        },
        /*subtitle: {
          text: this.$t('dashboard.salesByCountry.subtitle'),
          align: 'center'
        },*/
      }
    }
  }
}
</script>

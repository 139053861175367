<template>
  <v-card class="mx-auto form-card-padding text-center" style="height:100%;">
    <template v-if="setup.length == 0">
      <div>
        {{ $t('components.charts.loading') }}
      </div>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
        center
      ></v-progress-circular>
    </template>

    <apexchart
      v-if="setup.length != 0"
      :options="options"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
import apexchart from 'vue-apexcharts'

export default {
  components: { apexchart },
  name: 'CustomerTypes',

  props: {
    setup: Array
  },
  watch: {
    setup: {
      deep: true,
      handler () {
        let seriesData = []
        let typesData = []

        this.setup.forEach(function (n) {
          seriesData.push(n.count)
          typesData.push(n.type)
        })

        this.series[0].data = seriesData
        this.options.xaxis.categories = typesData

        this.options = { ...this.options }
      }
    }
  },
  data () {
    return {
      options: {
        chart: {
          width: '100%',
          height: '100%',
          type: 'bar',
          id: 'vuechart-customer-type'
        },
        title: {
          text: this.$t('dashboard.customerTypes.title')
        },
        xaxis: {
          categories: []
        },
        colors: [
          '#33b2df',
          '#546E7A',
          '#d4526e',
          '#13d8aa',
          '#A5978B',
          '#2b908f',
          '#f9a3a4',
          '#90ee7e',
          '#f48024',
          '#69d2e7'
        ],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val /*opt*/) {
            return val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: this.$t('dashboard.customerTypes.title'),
          data: []
        }
      ]
    }
  }
}
</script>

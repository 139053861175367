<template>
  <v-container class="grey lighten-5 mb-6">
    <v-row>
      <v-col col-12 class="main-col-margin">
        <v-card>
          <v-card-title>
            <v-icon large left> mdi-check-all</v-icon>
            <span class="title">{{ $t('project.filters.title') }}</span>

            <v-btn
              class="button-right"
              icon
              @click="showFilters = !showFilters"
            >
              <v-icon>{{
                showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-expand-transition>
            <div v-show="showFilters">
              <v-container class="grey lighten-5">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-date-picker
                      v-model="filters.dateRange"
                      range
                      full-width
                      :selected-items-text="selectedDates"
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-card color="#385F73" dark outlined height="100%">
                      <v-card-title class="headline">
                        {{ $t('project.filters.status.title') }}
                      </v-card-title>

                      <v-card-subtitle>
                        {{ $t('project.filters.status.subtitle') }}
                      </v-card-subtitle>

                      <v-card-text>
                        <v-switch
                          class="v-card-switch-no-padding-top"
                          v-for="status in statusFilters"
                          :key="'status_' + status"
                          v-model="filters.status"
                          color="primary"
                          :label="$t(`project.filters.status.status.${status}`)"
                          :value="status"
                          :hide-details="true"
                        ></v-switch>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="6" class="main-col-margin">
                    <v-autocomplete
                      style="margin-top: 10px"
                      v-model="filters.countries"
                      :items="countries"
                      dense
                      filled
                      :label="$t('project.filters.countries')"
                      multiple
                      item-value="id"
                      item-text="nicename"
                    ></v-autocomplete>

                    <v-autocomplete
                      v-if="isUserAdmin"
                      style="margin-top: 10px"
                      v-model="filters.vendors"
                      :items="vendors"
                      dense
                      filled
                      :label="$t('project.filters.vendors')"
                      multiple
                      item-value="id"
                      item-text="name"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-btn color="success" @click="getData()">
                  {{ $t('project.filters.controlls.apply') }}
                </v-btn>
                <v-btn color="error" @click="clearFilters()">
                  {{ $t('project.filters.controlls.clear') }}
                </v-btn>
              </v-card-actions>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <sales-by-country :setup="salesByCountry"></sales-by-country>
      </v-col>
      <v-col cols="12" sm="6">
        <customer-types :setup="customerTypes"></customer-types>
      </v-col>
      <v-col cols="12" sm="6">
        <project-sectors :setup="sectors"></project-sectors>
      </v-col>
      <v-col cols="12" sm="6">
        <project-sectors-and-applications
          :setup="sectorsAndApplications"
        ></project-sectors-and-applications>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>
<script>
import SalesByCountry from '../components/charts/SalesByCountry.vue'
import CustomerTypes from '../components/charts/CustomerTypes.vue'
import ProjectSectorsAndApplications from '../components/charts/ProjectSectorsAndApplications.vue'
import ProjectSectors from '../components/charts/ProjectSectors.vue'

export default {
  components: {
    SalesByCountry,
    CustomerTypes,
    ProjectSectorsAndApplications,
    ProjectSectors
  },

  name: 'Dashboard',
  data () {
    return {
      salesByCountry: [],
      customerTypes: [],
      sectorsAndApplications: [],
      sectors: [],

      showFilters: false,

      filters: {
        dateRange: [],
        status: [],
        countries: [],
        vendors: []
      }
    }
  },
  computed: {
    applicationData () {
      return this.$store.state.applicationData
    },
    isUserAdmin () {
      return this.$store.getters.getUserData.admin
    },
    statusFilters () {
      if (this.applicationData) {
        return this.applicationData.project.constants.statuses
      }
      return []
    },
    countries () {
      if (this.applicationData) {
        return this.applicationData.countries
      }
      return []
    },
    vendors () {
      if (this.applicationData) {
        return this.applicationData.vendors
      }
      return []
    },
    selectedDates () {
      if (this.filters.dateRange.length != 2) {
        return this.$root.$t('project.filters.date.selectDate')
      } else {
        return this.$root.$t('project.filters.date.range', {
          from: this.$root.moment(this.filters.dateRange[0]).format('DD.MM.YYYY'),
          to: this.$root.moment(this.filters.dateRange[1]).format('DD.MM.YYYY')
        })
      } 
    }
  },
  mounted () {
    this.$store.dispatch('loadApplicationData')
    this.getData()
  },
  methods: {
    getData () {
      this.$axios({
        method: 'post',
        url: this.$globals.api.home.getStatistics + this.$store.getters.getUserData.id,
        data: this.filters
      })
        .then(response => {
          this.salesByCountry = response.data.data.salesByCountry
          this.customerTypes = response.data.data.customerTypes
          this.sectorsAndApplications = response.data.data.sectorsAndApplications
          this.sectors = response.data.data.sectors
        })
        .catch(error => {
          this.error = error.response
        })
    },
    clearFilters(){
      this.filters = {
        dateRange: [],
        status: [],
        countries: [],
        vendors: [],
      }
      this.getData()
    },
  }
}
</script>
